import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'

@Injectable()
export class ApiConstants {
  readonly baseUrl = environment.baseUrl
  // readonly baseUrl = 'https://api.geotechpa.com/';
  // readonly baseUrl = 'https://geotech-api-dev.azurewebsites.net/';
  // readonly baseUrl = 'https://geotechcasts.azurewebsites.net/';

  public Billing = {
    query: this.baseUrl + 'billing/query',
    save: this.baseUrl + 'billing/save',
    saveAdmin: this.baseUrl + 'billing/saveAdmin',
    saveTimesheetDescription: this.baseUrl + 'billing/saveTimesheetDescription',
    sendtointacct: this.baseUrl + 'billing/sendtointacct',
    syncprogress: this.baseUrl + '/billing/issyncinprogress',
    downloadprojectorbillingreport: this.baseUrl + 'billing/DownloadProjectorBillingReport'
  }

  public Contact = {
    findQuick: this.baseUrl + 'Contact/FindQuick',
    query: this.baseUrl + 'Contact/Query',
    projectInvolvement: this.baseUrl + 'Contact/ProjectInvolvement',
    update: this.baseUrl + 'Contact/Update',
    delete: this.baseUrl + 'Contact/Delete',
    removeOverride: this.baseUrl + 'Contact/RemoveOverride',
    duplicityCheck: this.baseUrl + 'Contact/DuplicityCheck',
  }

  public Project = {
    get: this.baseUrl + 'Project/Get',
    getSimple: this.baseUrl + 'Project/GetSimple',
    getByProjectNumber: this.baseUrl + 'Project/GetByProjectNumber',
    getAll: this.baseUrl + 'Project/GetAll',
    findQuick: this.baseUrl + 'Project/FindQuick',
    findQuickWithExistingLog: this.baseUrl + 'Project/FindQuick/LogExists',
    findQuickWithExistingFieldReport:
      this.baseUrl + 'Project/FindQuick/FieldReportExists',
    findQuickWithExistingSoilTest:
      this.baseUrl + 'Project/FindQuick/SoilTestExists',
    findQuickWithExistingConcreteTest:
      this.baseUrl + 'Project/FindQuick/ConcreteTestExists',
    findQuickWithExistingUnapprovedFieldReportConcreteTest:
      this.baseUrl +
      'Project/FindQuick/UnapprovedFieldReportConcreteTestExists',
    findQuickActive: this.baseUrl + 'Project/FindQuick/Active',
    query: this.baseUrl + 'Project/Query',
    syncProjects: this.baseUrl + 'Project/SyncProjects',
    isSyncInProgress: this.baseUrl + 'Project/IsSyncInProgress',
    getDistributions: this.baseUrl + 'Project/{projectId}/Distribution',
    getMixDesigns: this.baseUrl + 'Project/{projectId}/MixDesign',
    updateDetails: this.baseUrl + 'Project/UpdateDetails',
    updateDistribution:
      this.baseUrl + 'Project/{projectId}/Distribution/Update',
    updateMixDesign: this.baseUrl + 'Project/{projectId}/MixDesign/Update',
    projectNumberExists: this.baseUrl + 'Project/ProjectNumberExists',
    soilTestCount: this.baseUrl + 'Project/SoilTestCount',
    concreteTestCount: this.baseUrl + 'Project/ConcreteTestCount',
  }

  public FieldReport = {
    query: this.baseUrl + 'FieldReport/Query',
    delete: this.baseUrl + 'FieldReport/Delete',
    findQuick: this.baseUrl + 'FieldReport/FindQuick',
    get: this.baseUrl + 'FieldReport/Get',
    getSimple: this.baseUrl + 'FieldReport/GetSimple',
    getSimpleByIds: this.baseUrl + 'FieldReport/GetSimpleByIds',
    update: this.baseUrl + 'FieldReport/Update',
    approve: this.baseUrl + 'FieldReport/Approve',
    sendPrelimEmail: this.baseUrl + 'FieldReport/SendPrelimEmailV2',
    getTypes: this.baseUrl + 'FieldReport/Types',
    getAttachments: this.baseUrl + 'FieldReport/Attachments',
    hasUnreconciledRetests: this.baseUrl + 'FieldReport/HasUnreconciledRetests',
    getProjectByFieldReportId:
      this.baseUrl + 'FieldReport/ProjectByFieldReportId',
    getSoilTest: this.baseUrl + 'FieldReport/SoilTest',
    getSoilTests: this.baseUrl + 'FieldReport/SoilTests',
    updateSoilTest: this.baseUrl + 'FieldReport/SoilTest/Update',
    deleteSoilTest: this.baseUrl + 'FieldReport/SoilTest/Delete',
    getSoilTestSpecific: this.baseUrl + 'FieldReport/SoilTestSpecific',
    updateSoilTestSpecific:
      this.baseUrl + 'FieldReport/SoilTestSpecific/Update',
    queryConcreteTest: this.baseUrl + 'FieldReport/ConcreteTest/Query',
    unassignedCylinders:
      this.baseUrl + 'FieldReport/ConcreteTest/UnassignedCylinders',
    getConcreteTest: this.baseUrl + 'FieldReport/ConcreteTest',
    checkInCylinders:
      this.baseUrl + 'FieldReport/ConcreteTest/CheckInCylinders',
    getConcreteTests: this.baseUrl + 'FieldReport/ConcreteTests',
    getUnassignedCylinders:
      this.baseUrl + 'FieldReport/ConcreteTest/UnassignedCylinders',
    getConcreteTestData: this.baseUrl + 'FieldReport/ConcreteTestData',
    getConcreteSampleTypes: this.baseUrl + 'FieldReport/ConcreteSampleTypes',
    updateConcreteTest: this.baseUrl + 'FieldReport/ConcreteTest/Update',
    approveConcreteTest: this.baseUrl + 'FieldReport/ConcreteTest/Approve',
    deleteConcreteTest: this.baseUrl + 'FieldReport/ConcreteTest/Delete',
    updateConcreteTestData:
      this.baseUrl + 'FieldReport/ConcreteTestData/Update',
    updateConcreteSampleTypes:
      this.baseUrl + 'FieldReport/ConcreteSampleTypes/Update',
    validProjectNumber: this.baseUrl + 'FieldReport/ValidProject',
    //createReportItems: this.baseUrl + 'FieldReport/CreateReportItems',
    constructReportSources: this.baseUrl + 'FieldReport/ConstructReportSources',
    openAttachment: this.baseUrl + 'FieldReport/Attachment',
    deleteAttachmentByGuid: this.baseUrl + 'FieldReport/deleteAttachmentByGuid',
    removeReportCreated: this.baseUrl + 'FieldReport/RemoveReportCreated',
    markReportCreated: this.baseUrl + 'FieldReport/MarkReportCreated',
  }

  public Distribution = {
    item: this.baseUrl + 'Distribution/Item',
    query: this.baseUrl + 'Distribution/Query',
    // approve: this.baseUrl + 'Distribution/Approve',
    send: this.baseUrl + 'Distribution/Send',
    delete: this.baseUrl + 'Distribution/Delete',
    createReportItem: this.baseUrl + 'Distribution/CreateReportItem',
    uploadAttachment: this.baseUrl + 'Distribution/UploadAttachment',
    updateAttachmentItems: this.baseUrl + 'Distribution/UpdateAttachmentItems',
    openAttachment: this.baseUrl + 'Distribution/Attachment',
    downloadoAttachment: this.baseUrl + 'Distribution/DownloadAttachment',
  }

  public Soils = {
    query: this.baseUrl + 'Soils/Query',
    selectionForProject: this.baseUrl + 'Soils/Selection',
    determinePage: this.baseUrl + 'Soils/DeterminePage',
    create: this.baseUrl + 'Soils/Create',
    update: this.baseUrl + 'Soils/Update',
    get: this.baseUrl + 'Soils/Get',
    types: this.baseUrl + 'Soils/Types',
    updateTypes: this.baseUrl + 'Soils/Types/Update',
    ranges: this.baseUrl + 'Soils/Ranges',
    soilTestsByFieldReportSoilTestId:
      this.baseUrl + 'Soils/ByFieldReportSoilTestIds',
    reconcileRetest: this.baseUrl + 'Soils/ReconcileRetest',
    nextRetestId: this.baseUrl + 'Soils/NextRetestId',
    moveTests: this.baseUrl + 'Soils/MoveTests',
  }

  public Report = {
    url: this.baseUrl + 'api/reports',
  }

  public Logs = {
    getRocks: this.baseUrl + 'RockLog',
    getUndercuts: this.baseUrl + 'UndercutLog',
    getDiscrepancies: this.baseUrl + 'DiscrepancyLog',

    getRocksByProjectId: this.baseUrl + 'RockLog/ByProjectId',
    getUndercutsByProjectId: this.baseUrl + 'UndercutLog/ByProjectId',
    getDiscrepanciesByProjectId: this.baseUrl + 'DiscrepancyLog/ByProjectId',

    rockLogCreate: this.baseUrl + 'RockLog/Create',
    undercutCreate: this.baseUrl + 'UndercutLog/Create',
    discrepancyCreate: this.baseUrl + 'DiscrepancyLog/Create',

    rockLogUpdate: this.baseUrl + 'RockLog/Update',
    undercutUpdate: this.baseUrl + 'UndercutLog/Update',
    discrepancyUpdate: this.baseUrl + 'DiscrepancyLog/Update',

    //list of values
    getRockTypes: this.baseUrl + 'RockLog/RockTypes',
    getRockReplacement: this.baseUrl + 'RockLog/RockReplacements',
  }

  public Authentication = {
    login: this.baseUrl + 'Users/Login',
    logout: this.baseUrl + 'Users/Logout',
    isAuthenticationValid: this.baseUrl + 'Users/IsAuthenticationValid',
    refreshToken: this.baseUrl + 'Users/RefreshToken',
    getLoggedUser: this.baseUrl + 'Users/GetByToken',
  }

  public Clients = {
    getAllClients: this.baseUrl + 'Client/GetAll',
    findQuick: this.baseUrl + 'Client/FindQuick',
  }

  public Users = {
    query: this.baseUrl + 'Users/Query',
    getUserNamesById: this.baseUrl + 'Users/GetUserNamesById',
    resetPassword: this.baseUrl + 'Users/ResetPassword',
    getUserTypes: this.baseUrl + 'Users/UserTypes',
    createUser: this.baseUrl + 'Users/Create',
    updateUser: this.baseUrl + 'Users/Update',
    loginDuplicityCheck: this.baseUrl + 'Users/LoginDuplicityCheck',
  }

  public Concrete = {
    query: this.baseUrl + 'Concrete/Query',
    queryLabs: this.baseUrl + 'Concrete/QueryLabs',
    getSampleTypes: this.baseUrl + 'Concrete/SampleTypes',
    searchByProject: this.baseUrl + 'Concrete/SearchByProject',
    getById: this.baseUrl + 'Concrete/GetById',
    getByLabNumber: this.baseUrl + 'Concrete/GetByLabNumber',
    getAuditHistory: this.baseUrl + 'Concrete/GetAuditHistory',
    getData: this.baseUrl + 'Concrete/GetData',
    create: this.baseUrl + 'Concrete/Create',
    update: this.baseUrl + 'Concrete/Update',
    updateData: this.baseUrl + 'Concrete/UpdateData',
    breakListQuery: this.baseUrl + 'Concrete/BreakListQuery',
    updateBreakList: this.baseUrl + 'Concrete/UpdateBreakListItem',
    GetMixDesignNumbers: this.baseUrl + 'Concrete/GetMixDesignNumbers',
    findQuickMixDesigns: this.baseUrl + 'Concrete/FindQuickMixDesigns',
    nextLabNumber: this.baseUrl + 'Concrete/NextLabNumber',
    isLabNumberAssigned: this.baseUrl + 'Concrete/IsLabNumberAssigned',
    // skippedLabNumbers: this.baseUrl + 'Concrete/SkippedLabNumbers'
  }

  public Settings = {
    getAll: this.baseUrl + 'Settings/GetAll',
    updateSettings: this.baseUrl + 'Settings/UpdateSettings',
    getFieldReportAttachmentPath:
      this.baseUrl + 'Settings/GetFieldReportAttachmentPath',
    getDistributionAttachmentPath:
      this.baseUrl + 'Settings/GetDistributionAttachmentPath',
    getEnvironment: this.baseUrl + 'Settings/GetEnvironment',
  }
}
