import {
  HttpBackend,
  HttpClient,
  HttpErrorResponse,
} from "@angular/common/http";
import { Directive, EventEmitter, Injectable, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiConstants } from "app/main/shared/constants/api.constants";
import { Authentication } from "app/main/shared/models/authentication.model";
import { BehaviorSubject, interval, Observable } from "rxjs";
import { startWith } from "rxjs/operators";
import { ToastService } from "./toast.service";
import { Buffer } from 'buffer';
import { MessagesConstants } from "app/main/shared/constants/messages.constants";

@Directive()
@Injectable({ providedIn: "root" })
export class AuthenticationService {
  @Output() loggedIn: EventEmitter<null> = new EventEmitter();

  private authenticationSubject: BehaviorSubject<Authentication>;
  public authentication: Observable<Authentication>;

  constructor(
    private http: HttpClient,
    private api: ApiConstants,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private handler: HttpBackend,
    private messages: MessagesConstants,
  ) {
    this.authenticationSubject = new BehaviorSubject<Authentication>(
      JSON.parse(localStorage.getItem("authentication"))
    );
    this.authentication = this.authenticationSubject.asObservable();

    // check for possible refresh every 15 minutes
    interval(900000)
      .pipe(startWith(0))
      .subscribe(() => this.tryRefreshToken())
  }
  
  private tryRefreshToken() {
    const authentication = this.authenticationSubject.value;
    if (authentication?.token)
      new HttpClient(this.handler)
        .post(
          this.api.Authentication.refreshToken,
          authentication, {
            responseType: "text",
            headers: { Authorization: "Bearer " + authentication.token }} )
        .toPromise()
        .then(token => {
          if (token && authentication.token !== token) {
            authentication.token = token;
            localStorage.setItem('authentication', JSON.stringify(authentication));
            this.authenticationSubject.next(authentication);
          }
        })
        .catch(_ => this.toastService.showToast(this.messages.ToastMessages.authorizationRefreshError));
  }

  public get currentAuthentication(): Authentication {
    return this.authenticationSubject.value;
  }

  public get loggedUserNavItem(): any {
    return {
      id: "logged-user",
      icon: "person",
      title: this.currentAuthentication.loggedUser.name,
      type: "group",
      children: [
        {
          id: "logout",
          title: "Logout",
          icon: "exit_to_app",
          type: "item",
          function: () => {
            this.logout();
          },
        },
      ],
    };
  }

  public isAuthenticated(): boolean {
    if (this.currentAuthentication && this.currentAuthentication.token) {
      return true;
    }

    return false;
  }

  public isAdmin(): boolean {
    if (this.authenticationSubject.value) {
      return this.authenticationSubject.value.role == "Admin";
    } else {
      return false;
    }
  }

  public isPowerUser(): boolean {
    if (this.authenticationSubject.value) {
      return this.authenticationSubject.value.role == "Power User";
    } else {
      return false;
    }
  }

  login(login: string, password: string) {
    return new HttpClient(this.handler)
      .post(
        this.api.Authentication.login,
        { login, password },
        { responseType: "text" }
      )
      .toPromise()
      .then((response) => {
        let authentication: Authentication = JSON.parse(response);
        localStorage.setItem("authentication", response);
        this.authenticationSubject.next(authentication);
        this.loggedIn.emit();

        this.activatedRoute.snapshot.queryParamMap.get("returnUrl")
          ? this.router.navigate([
              this.activatedRoute.snapshot.queryParamMap.get("returnUrl"),
            ])
          : this.router.navigate(["/"]);
      })
      .catch((errorRespone: HttpErrorResponse) => {
        this.toastService.showToast(errorRespone.error);
      });
  }

  logout() {
    let queryParams = {};

    if (this.router.routerState.snapshot.url != "/") {
      queryParams = {
        queryParams: { returnUrl: this.router.routerState.snapshot.url },
      };
    }

    return this.http
      .delete(this.api.Authentication.logout)
      .subscribe(_ => {
        this.removeAuthentication();
        this.router.navigate([""], queryParams);
      });
  }

  removeAuthentication() {
    localStorage.removeItem("authentication");
    this.authenticationSubject.next(null);
  }

  isAuthenticationValid(authentication: Authentication): Observable<boolean> {
    return this.http.post<boolean>(
      this.api.Authentication.isAuthenticationValid,
      authentication
    );
  }

 
}
