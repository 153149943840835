import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { AuthenticationService } from "app/services/authentication.service";
import { ToastService } from "app/services/toast.service";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { MessagesConstants } from "../shared/constants/messages.constants";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private toastService: ToastService,
    private router: Router,
    private messages: MessagesConstants
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status !== 401)
          this.toastService.showToast(this.messages.ToastMessages.requestError);
        if (
          (error.status === 401 || error.status === 200) &&
          this.authenticationService.currentAuthentication
        ) {
          this.toastService.showToast(this.messages.ToastMessages.unauthorized);
          this.authenticationService.removeAuthentication();
          setTimeout(() => {            
            location.reload();
          }, 3000);
        } else if (error.status === 404) {
          this.router.navigate(["404"]);
          return throwError(error);
        } else {
          this.router.navigate(["error"], {
            state: {
              error: JSON.parse(JSON.stringify(error)),
            },
          } as NavigationExtras);
          return throwError(error);
        }
      })
    );
  }
}
