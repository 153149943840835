import { Injectable } from "@angular/core";

@Injectable()
export class MessagesConstants {
  public readonly InvalidFields = {
    date: "Invalid date format.",
    time: "Invalid time format.",
    wholeNumber: "Must be a whole number.",
    decimalNumber: "Must be a whole or decimal number.",
    required: "This field is required.",
    minLength: (minLength: number): string => `Minimum length of ${minLength} characters.`,
    maxLength: (maxLength: number): string => `Maximum length of ${maxLength} characters.`,
    minMaxLength: (minLength: number, maxLength: number): string => `Minimum length of ${minLength} characters, maximum length of ${maxLength} characters.`,
    max: (max: number): string => `Can't be greater than ${max}.`,
    projectNumberNotExists: "Project number does not exist.",
    duplicityLogin: "User with this login already exists."
  }

  public readonly ToastMessages = {
    invalidForm: "Form is invalid.",
    undoComplete: "Undo complete.",
    saveComplete: "Save complete.",
    saveFail: "Save fail. Invalid value.",
    approveComplete: "Approve complete.",
    reportsGenerated: "Report(s) generated and saved to the repository.",
    fieldReportInvalidProjectNumber: "Field Report has invalid Project Number.",
    testsWereMoved: "Soil tests were successfully moved.",
    requestError: "An error occurred while processing the request.",
    unauthorized: 'Unauthorized',
    authorizationRefreshError: "Unable to reauthenticate.",
    invalidMixDesign: "Mix Design number is invalid.",
    invalidStrength: "Specified Strength doesn't match Mix Design Strength."
  }

  public readonly DialogTitles = {
    filtersApplied: "Remove Filters",
    pendingChanges: "Save Progress"
  }

  public readonly DialogMessages = {
    undo: "Are you sure you want to undo all pending changes?",
    leave: "Pending changes have been detected. Continuing may result in loss of work. Are you sure you want to continue?",
    deleteConcretePour: "Are you sure you want to delete this Concrete Pour?",
    deleteSoilTest: "Are you sure you want to delete this Soil Test?",
    deleteAttachment: "Are you sure you want to delete this attachment?",
    labNumberAssigned: "Your Lab Number has already been assigned. Press Yes to get a new Lab Number. You will need to save again.",
    concreteSampleTypeChange: "You have changed the Concrete Sample Type. All cylinders will be recalculated! Are you sure you want to continue?",
    filtersApplied: "Synching is disabled while filters are applied.<br/>Please remove filters before attempting to Sync to IntAcct.",
    pendingChanges: "Wait... You should make sure to save your progress!<br/>Save now or discard the recent changes."
  }

  public readonly Hints = {
    filterOnlyApproved: "Filter only approved field reports.",
    commaDelimitedList: "Comma delimited list.",
    minimumCharacters: (min: number): string => `At least ${min} characters.`,
    noRowsSelected: "No rows selected.",
    noReportCreated: "No report created.",
    noProjectSelected: "No project selected.",
    invalidValue: "Value is invalid."
  }
}